<template>
  <div class="UserIndicator">
    <div
      class="UserIndicator-icon"
      :style="{'--color': bgColor, '--image': image}"
    >
      <span>
        <span :data-initials="initials.toUpperCase()" />
      </span>
    </div>
    <span
      v-if="!icon"
      class="UserIndicator-name"
    >{{ name }}</span>
  </div>
</template>
<script>
export default {
  tag: 'user-indicator',
  props: {
    name: {
      type: String,
      default: ''
    },
    icon: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    bgColor () {
      const s = 25
      const l = 60
      let hash = 0

      for (let i = 0; i < this.name.length; i++) {
        hash = this.name.charCodeAt(i) + ((hash << 5) - hash)
      }

      const h = hash % 360
      return 'hsl(' + h + ', ' + s + '%, ' + l + '%)'
    },
    initials () {
      return this.name.substring(0, 2).toUpperCase()
    },
    image () {
      return 'url(\'' + '/api/v1/user/avatar/' + encodeURIComponent(this.name) + '\')'
    }
  }
}
</script>
