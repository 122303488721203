import MaterialIcon from '@/components/_partials/Atom/FomanticIcon.ce.vue'
import EntityLink from '@/components/_partials/Molecule/EntityLink.ce.vue'
import DetailTable from '@/components/_partials/Molecule/AccordionTable.ce.vue'
import TaskHistory from '@/components/_partials/Organism/TaskHistory.ce.vue'
import ServiceTable from '@/components/_partials/Organism/ServiceTable.ce.vue'
import WindowButton from '@/components/_partials/WindowButton.vue'
import AvatarEditor from '@/components/_partials/Organism/AvatarEditor.ce.vue'
import OpenAccessIcon from '@/components/_partials/Atom/OpenAccessIcon.ce.vue'

export default [
  MaterialIcon,
  EntityLink,
  WindowButton,
  AvatarEditor,
  OpenAccessIcon,
  DetailTable,
  TaskHistory,
  ServiceTable
]
