import { createApp } from 'vue'
import * as R from 'ramda'
import { createPinia } from 'pinia'
import vueCustomElements from '@/js/components'

function compareProp (prop, propType) {
  return prop === propType || prop.type === propType
}

function getMountPoint (mountId) {
  return mountId instanceof Element
    ? mountId
    : document.getElementById(mountId)
}

function convertPropType (prop, value) {
  if (compareProp(prop, Number)) {
    return R.isNil(value) || R.isEmpty(value) ? null : parseFloat(value)
  } else if (compareProp(prop, Boolean)) {
    return typeof value !== 'undefined'
  } else if ((compareProp(prop, Object) || compareProp(prop, Array)) &&
    R.is(String, value)) {
    return JSON.parse(value)
  }

  return value
}

function convertProp (dataset, componentProps, dataSetProp, key) {
  const dataSetValue = dataset[dataSetProp]

  if (componentProps && key in componentProps) {
    const prop = componentProps[key]

    return convertPropType(prop, dataSetValue)
  }
  return dataSetValue
}

/**
 * Creates a Vue component on mountId.
 *
 * datasetProp is a map where the key is a prop name and the value – dataset
 * attribute name. If it is given, createVue will read dataset properties on
 * mountId and try to convert these to the type requested by the Vue component
 * for the appropriate property. Boolean properties are always true if the
 * dataset attribute exists, numbers are parsed with Number.parseFloat(),
 * objects and arrays with JSON.parse().
 *
 * The function does nothing if mountId doesn't exist in the DOM and returns
 * null.
 *
 * @param {Object} component - Vue component.
 * @param {string|Element} mountId - Where the Vue component should be mounted.
 * @param {Object.<string, a>} rootProps - Props passed directly to the Vue component.
 * @param {Object.<string, string> datasetProps - Mapping of Vue properties to dataset attributes.
 * @param useState set to true if you want to use pinia (state Management), default false
 *
 * @returns {?Vue} Returns null if mountId doesn't exist in the DOM, otherwise the created Vue component.
 */
export function createVue (component, mountId, rootProps = {}, datasetProps = {}, useState = false) {
  const mountPoint = getMountPoint(mountId)
  if (mountPoint === null) {
    return null
  }

  function mapDataSetProp (dataSetProp, key) {
    return convertProp(mountPoint.dataset, component.props, dataSetProp, key)
  }

  const additionalProps = R.mapObjIndexed(mapDataSetProp, datasetProps)
  const app = createApp(component, R.mergeAll([rootProps, additionalProps]))

  if (useState) {
    const pinia = createPinia()
    app.use(pinia)
  }

  const instance = app.mount(mountPoint)

  app.config.globalProperties.refs = instance.$refs

  return app
}

export function registerComponents () {
  vueCustomElements.forEach((vueElement) => {
    if (!vueElement.tag) {
      return
    }

    document.querySelectorAll(vueElement.tag)
      .forEach((element) => {
        const rootProps = {}

        for (const attr of Object.values(element.attributes)) {
          const key = hyphenToCamelCase(attr.nodeName)
          const value = attr.nodeValue

          if (key in vueElement.props) {
            rootProps[key] = JSON.parse(value)
          } else {
            rootProps[key] = value
          }
        }

        for (const [key, prop] of Object.entries(vueElement.props)) {
          if (!(key in rootProps) && 'default' in prop) {
            let def = prop.default
            if (typeof def === 'function') {
              def = def()
            }
            rootProps[key] = def
          }
        }

        createVue(vueElement, element, rootProps)
      })
  })
}

export function hyphenToCamelCase (str) {
  return str
    .toLowerCase()
    .split('-')
    .map((word, index) => {
      if (index === 0) {
        return word
      }
      return word.charAt(0).toUpperCase() + word.slice(1)
    })
    .join('')
}
