<template>
  <div
    id="UserPrivilegeAccessRights"
    class="box show-rights-box"
  >
    <div
      id="privileges"
      class="list-privileges"
    >
      <label>
        Privileg
      </label>
      <sui-dropdown
        v-model="privilegeInput"
        :options="privileges"
        placeholder="Bitte Privileg wählen!"
        search
        selection
      />
    </div>

    <section v-if="description">
      <h3>Beschreibung:</h3>
      <!-- eslint-disable-next-line vue/no-v-html -->
      <div v-html="description" />
    </section>

    <h1 class="table-head">
      Effektive Zugriffsrechte
    </h1>

    <sui-table
      celled
      class="ui blue table"
    >
      <sui-table-header>
        <sui-table-row>
          <sui-table-header-cell>
            Nutzer
          </sui-table-header-cell>
          <sui-table-header-cell>
            gegeben durch
          </sui-table-header-cell>
        </sui-table-row>
      </sui-table-header>

      <sui-table-body>
        <sui-table-row
          v-for="privilege in userPrivileges"
          :key="privilege.username"
        >
          <sui-table-cell class="aTableCell">
            <p
              class="group"
              :class="{disabled: !privilege.is_enabled}"
            >
              <a
                :href="'/settings/' + privilege.user_id"
                target="_blank"
              >
                {{ privilege.username }}
              </a>
            </p>
          </sui-table-cell>

          <sui-table-cell class="aTableCell">
            <span>
              <span
                v-if="privilege.is_given_directly"
              >
                direkt<span v-if="Object.keys(privilege.groups).length > 0">, </span>
              </span>
              <span
                v-for="(groupName, id, index) in privilege.groups"
                :key="id"
                class="group"
              >
                <a
                  :href="'/admin/group/edit/' + id"
                  target="_blank"
                >
                  <span>
                    Gruppe: {{ groupName }}<span v-if="index+1 < Object.keys(privilege.groups).length">, </span>
                  </span>
                </a>
              </span>
            </span>
          </sui-table-cell>
        </sui-table-row>
      </sui-table-body>
      <sui-table-footer v-if="!selectedPrivilege">
        <sui-table-row>
          <sui-table-header-cell colspan="4">
            <div class="center">
              <p>Wählen Sie bitte ein Privileg, um zugriffsrechte anzuzeigen.</p>
            </div>
          </sui-table-header-cell>
        </sui-table-row>
      </sui-table-footer>
    </sui-table>
  </div>
</template>

<script>
import { request } from '@/js/request'
import {
  SuiTable,
  SuiTableFooter,
  SuiTableRow,
  SuiTableHeaderCell,
  SuiTableBody,
  SuiTableCell,
  SuiTableHeader,
  SuiDropdown
} from 'vue-fomantic-ui'

export default {
  components: {
    SuiTable,
    SuiTableFooter,
    SuiTableRow,
    SuiTableHeaderCell,
    SuiTableBody,
    SuiTableCell,
    SuiTableHeader,
    SuiDropdown
  },
  data () {
    return {
      privileges: [],
      userPrivileges: [],
      description: null,
      selectedPrivilege: null
    }
  },
  computed: {
    privilegeInput: {
      get () {
        return this.selectedPrivilege
      },
      set ({ value }) {
        this.getUserPrivileges(value[0])
        this.description = value[1]
        this.selectedPrivilege = value[2]
      }
    }
  },
  created () {
    this.getPrivilegesList()
  },
  methods: {
    getPrivilegesList () {
      request('get', '/api/v1/user/privilege/list',
        response => {
          this.privileges = response.map(function (privilege) {
            return {
              text: privilege.title,
              value: [privilege.id, privilege.description, privilege.title]
            }
          })
        }
      )
    },
    getUserPrivileges (userId) {
      request('get', '/api/v1/user/privilege/' + userId,
        response => {
          this.userPrivileges = response
        }
      )
    }
  }
}
</script>

<style scoped>
.show-rights-box {
  padding: 30px;
  overflow: visible;
}

.list-privileges {
  width: 40%;
}

.table-head {
  padding-top: 30px;
}

.ui.blue.table {
  padding-top: 10px !important;
}
</style>
