<template>
  <div class="AvatarEditor">
    <div
      class="AvatarEditor-overlay"
      @click="show = true"
      @mouseenter="hover = true"
      @mouseleave="hover = false"
    >
      <UserIndicator
        icon
        :name="name"
      />
      <SuiDimmer
        :active="hover"
      >
        <SuiHeader
          as="span"
          icon
          inverted
        >
          <SuiIcon name="pencil alternate" />
        </SuiHeader>
      </SuiDimmer>
    </div>

    <SuiModal v-model="show">
      <SuiModalHeader>Avatar ändern</SuiModalHeader>
      <SuiModalContent
        class="AvatarEditor-modal-content"
      >
        <SuiModalDescription>
          <div :class="{ui: true, form: true, loading, error: error !== null}">
            <div
              v-if="error !== null"
              class="ui error message"
            >
              <div class="header">
                Fehler
              </div>
              <p>{{ error.message }}</p>
            </div>
            <div class="AvatarEditor-image">
              <UserIndicator
                v-if="file === null"
                icon
                :name="name"
              />
              <ImageCropper
                format="jpeg"
                @error="(e) => onError(e)"
                @changed="(e) => onChanged(e)"
                @result="(e) => onResult(e)"
              />
            </div>
          </div>
        </SuiModalDescription>
      </SuiModalContent>
      <SuiModalActions>
        <SuiButton
          negative
          @click="() => abort()"
        >
          Abbrechen
        </SuiButton>
        <SuiButton
          positive
          :loading="loading"
          :disabled="loading || !save"
          @click="() => onSave()"
        >
          Speichern
        </SuiButton>
      </SuiModalActions>
    </SuiModal>
  </div>
</template>

<script>
import { SuiDimmer, SuiIcon, SuiModal, SuiModalHeader, SuiHeader, SuiButton, SuiModalActions, SuiModalContent, SuiModalDescription } from 'vue-fomantic-ui'
import UserIndicator from '@/components/_partials/Atom/UserIndicator.ce.vue'
import ImageCropper from '@/components/_partials/Molecule/ImageCropper.ce.vue'
import axios from 'axios'

export default {
  tag: 'avatar-editor',
  components: {
    ImageCropper,
    UserIndicator,
    SuiDimmer,
    SuiIcon,
    SuiModal,
    SuiModalHeader,
    SuiHeader,
    SuiButton,
    SuiModalActions,
    SuiModalContent,
    SuiModalDescription
  },
  props: {
    name: {
      type: String,
      default: ''
    }
  },
  data: () => ({
    show: false,
    hover: false,
    loading: false,
    error: null,
    save: false,
    file: null
  }),
  methods: {
    onChanged (file) {
      this.error = null
      this.file = file
    },
    onError (error) {
      this.error = error
    },
    onResult (file) {
      this.save = true
      this.file = file
    },
    abort () {
      this.loading = false
      this.error = null
      this.show = false
    },
    async onSave () {
      this.loading = true

      return axios
        .put('/api/v1/user/avatar/' + this.name, this.file)
        .then(() => {
          this.show = false
          window.location.reload()
        })
        .catch((e) => {
          this.error = e
        })
        .finally(() => {
          this.loading = false
        })
    }
  }
}
</script>
