<template>
  <div class="c-service-table">
    <span
      v-if="header"
    >
      {{ header }}
    </span>
    <AccordionTable
      :headers="Object.values(headers)"
      :items="services"
      :collapsible="false"
    >
      <template #row="props">
        <td>
          {{ props.item.typeName }}
        </td>
        <td>
          {{ props.item.serviceStart }}<br>
          {{ props.item.serviceEnd ? props.item.serviceEnd : '&ndash;' }}
        </td>
        <td class="two wide">
          <SuiIcon
            :name="getServiceStateIcon(props.item.status.name)"
            :color="getServiceStateColorClass(props.item.status.name)"
          />
          {{ props.item.statusLabel }}
        </td>
        <td>
          <div
            v-if="hasDSLAMOrFiberInfo(props.item.serviceInfo)"
            class="td-multi-lines"
          >
            <EntityLink
              v-if="props.item.serviceInfo.dslam_link"
              v-bind="props.item.serviceInfo.dslam_link"
            />
            <span v-if="props.item.serviceInfo.line_profile">
              Lineprofil: {{ props.item.serviceInfo.line_profile }}
            </span>
            <span v-if="props.item.serviceInfo.fiber">
              {{ props.item.serviceInfo.fiber }}
            </span>
          </div>
          <div v-else>
            &ndash;
          </div>
        </td>
        <td>
          <div
            v-if="hasPortInfo(props.item.serviceInfo)"
            class="td-multi-lines"
          >
            <span
              v-if="props.item.serviceInfo.dslam_port_number"
              class="dslamport"
            >
              Port: {{ props.item.serviceInfo.dslam_port_number }}
            </span>
            <span
              v-if="props.item.serviceInfo.switch_info"
            >
              {{ props.item.serviceInfo.switch_info }}
            </span>
            <span
              v-if="props.item.serviceInfo.line_id"
              class="lineid"
            >
              {{ props.item.serviceInfo.line_id }}
            </span>
          </div>
          <div v-else>
            &ndash;
          </div>
        </td>
        <td>
          {{ props.item.reference }}
        </td>
      </template>
      <template #header-right>
        <a
          class="ui button icon tertiary"
          :href="editLink"
        >
          <SuiIcon name="edit icon large" />
        </a>
      </template>
    </AccordionTable>
  </div>
</template>
<script>
import AccordionTable from '@/components/_partials/Molecule/AccordionTable.ce.vue'
import { SuiIcon } from 'vue-fomantic-ui'
import { request, url } from '@/js/request.js'
import EntityLink from '@/components/_partials/Molecule/EntityLink.ce.vue'

export default {
  components: {
    EntityLink,
    SuiIcon,
    AccordionTable
  },
  tag: 'service-table',
  props: {
    api: {
      type: String,
      default: null
    },
    collapsed: {
      type: Boolean,
      default: false
    },
    header: {
      type: String,
      default: ''
    },
    editLink: {
      type: String,
      default: ''
    }
  },
  data: () => ({
    services: null
  }),
  computed: {
    headers () {
      return {
        typeName: 'Art',
        date: 'Zeitraum',
        status: 'Status',
        fiberOrDslam: 'Faser/DSLAM',
        portOrLineId: 'Port/Line-ID',
        reference: 'Referenz'
      }
    }
  },
  mounted () {
    this.fetchServices()
  },
  methods: {
    getServiceStateIcon (type) {
      switch (type) {
        case 'CANCELED':
          return 'times circle outline'
        case 'PLANNED':
          return 'ellipsis horizontal'
        case 'ACTIVE':
          return 'check circle outline'
        default:
          return ''
      }
    },
    getServiceStateColorClass (type) {
      switch (type) {
        case 'CANCELED':
          return 'red'
        case 'PLANNED':
        case 'ACTIVE':
          return 'green'
        default:
          return ''
      }
    },
    fetchServices () {
      this.services = null
      request('get', url(this.api, {}), services => {
        this.services = services
      })
    },
    hasDSLAMOrFiberInfo (serviceInfo) {
      return serviceInfo.dslam_link || serviceInfo.line_profile || serviceInfo.fiber
    },
    hasPortInfo (serviceInfo) {
      return serviceInfo.switch_info || serviceInfo.line_id || serviceInfo.dslam_port_number
    }
  }
}
</script>
