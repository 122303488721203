<template>
  <div id="topicTreeSelect">
    <span
      v-if="label.length > 0"
      class="ui test small"
    >{{ label }}</span>
    <treeSelect
      id="treeselect"
      v-model="selectedTopics"
      :multiple="true"
      :options="topics"
      placeholder="Alle Themen anzeigen"
      clear-all-text="Auswahl zurücksetzen"
      clear-value-text="Thema entfernen"
      :flat="true"
      :searchable="false"
      name="themen_selected[]"
      @open="openMenu"
      @close="closeMenu"
    />
  </div>
</template>
<script>
import Treeselect from '@m-kusumgar/vue3-treeselect'
import '@m-kusumgar/vue3-treeselect/dist/vue3-treeselect.css'
import { request } from '@/js/request'

export default {
  components: {
    treeSelect: Treeselect
  },
  props: {
    initSelectedTopics: {
      type: Array,
      default: function () {
        return []
      }
    },
    label: {
      type: String,
      default: 'Themen'
    },
    event: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      topics: [],
      formOpen: false,
      selectedTopics: []
    }
  },
  watch: {
    selectedTopics: function (value, oldValue) {
      if (value.length === 0 && !this.formOpen && JSON.stringify(value) !== JSON.stringify(oldValue)) {
        setTimeout(() => {
          this.sendUpdate()
        }, 100)
      }
    }
  },
  created () {
    this.selectedTopics = this.initSelectedTopics
    request('get', '/api/v1/topic/list/tree', response => {
      this.topics = response
    })
  },
  methods: {
    openMenu: function () {
      this.formOpen = true
    },
    sendUpdate: function () {
      if (this.event) {
        this.$emit('changed', this.selectedTopics)
      } else {
        document.getElementById('treeSelectForm').submit()
      }
    },
    closeMenu: function () {
      this.formOpen = false
      this.sendUpdate()
    }
  }
}
</script>

<style>
.vue-treeselect__control {
  border-radius: 0 !important;
}
</style>
