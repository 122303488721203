<template>
  <i
    :data-partner="partner"
    :class="'icon OpenAccessIcon ' + size"
  />
</template>

<script>
export default {
  tag: 'open-access-icon',
  props: {
    partner: {
      type: String,
      default: 'OA'
    },
    size: {
      type: String,
      default: ''
    }
  }
}
</script>
