<template>
  <section>
    <div
      v-if="deletePreselectionConfirmation"
      class="ui warning message"
    >
      <i
        class="icon times"
        @click.prevent="deletePreselectionConfirmation = false"
      >close</i>
      <i class="icon question circle" />
      <span id="message-text">Möchten Sie die Vorlage {{ chosenPreselectionName }} wirklich löschen?</span>
      &nbsp;
      <a
        href="#"
        @click.prevent="onDeletePreselection"
      >
        Ja, löschen
      </a>
    </div>
    <input
      type="hidden"
      name="contents"
      :value="contents"
    >
    <input
      type="hidden"
      name="fieldGroups"
      :value="JSON.stringify(fieldGroups)"
    >
    <table
      aria-label="fieldGroupsTable"
      class="aTable aList fullSize box padded"
    >
      <thead class="thead">
        <tr class="aTableRow">
          <th
            id="preselectionAccordingServices"
            colspan="3"
          >
            Vorauswahl nach Diensten
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Daten befüllen nach Standard Dienstvorgaben</td>
          <td>
            <label>
              <select
                v-model="chosenPreselection"
                @change="onSelectPreselection"
              >
                <option
                  v-for="preselection of preselections"
                  :key="preselection.id"
                  :value="preselection.id"
                >
                  {{ preselection.name }}
                </option>
              </select>
            </label>
          </td>
          <td>
            <button @click.prevent="onApplyPreselection">
              Anwenden
            </button>
            <button
              v-if="changeable"
              @click.prevent="showDeletePreselectionConfirmation"
            >
              Löschen
            </button>
          </td>
        </tr>
        <tr v-if="changeable">
          <td>Aktuelle Angaben als Vorlage speichern</td>
          <td>
            <label>
              <input
                v-model="preselectionName"
                type="text"
              >
            </label>
          </td>
          <td>
            <button @click.prevent="onSavePreselection">
              Speichern
            </button>
          </td>
        </tr>
      </tbody>
    </table>
    <table
      v-for="(group, groupKey) of fieldGroups"
      :key="groupKey"
      aria-label=""
      class="aTable aList fullSize box padded"
    >
      <thead class="thead">
        <tr class="aTableRow">
          <th id="">
            {{ group.label }}
          </th>
          <th id="quantity">
            Menge
          </th>
          <th id="art">
            Art
          </th>
          <th id="factor">
            {{ group.factor }}
          </th>
          <th id="cost">
            {{ group.cost }}
          </th>
          <th id="additionalInfo">
            {{ group.additionalInfo }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(field, key) in group.fields"
          :key="key"
          class="aTableRow"
        >
          <td
            v-if="isCustomField(key)"
            class="aTableCell label"
          >
            <input
              v-model="fieldData[key].label"
              type="text"
              :disabled="disabled"
              @change="serializeContent"
            >
          </td>
          <td
            v-else
            class="aTableCell label"
          >
            {{ field.label }}
          </td>
          <td class="aTableCell">
            <label>
              <input
                v-model="fieldData[key].quantity"
                type="text"
                :disabled="disabled"
                @input="summarize(field, group)"
                @change="serializeContent"
              >
            </label>
          </td>
          <td class="aTableCell">
            <label>
              <input
                v-model="fieldData[key].kind"
                type="text"
                :disabled="!isCustomField(key) || disabled"
                @change="serializeContent"
              >
            </label>
          </td>
          <td class="aTableCell">
            <label>
              <input
                v-model="fieldData[key].factor"
                type="text"
                :disabled="disabled"
                @input="summarize(field, group)"
                @change="serializeContent"
              >
            </label>
          </td>
          <td class="aTableCell">
            <label>
              <input
                type="text"
                :value="field.product"
                disabled
              >
            </label>
          </td>
          <td class="aTableCell">
            <label>
              <input
                v-model="fieldData[key].company"
                type="text"
                :disabled="disabled"
                @change="serializeContent"
              >
            </label>
          </td>
        </tr>
      </tbody>
      <tfoot class="table-total">
        <tr>
          <td
            class="aTableCell label"
            colspan="2"
          >
            <button
              type="button"
              @click.prevent="addLine(group, groupKey)"
            >
              + weitere Zeile
            </button>
          </td>
          <td
            class="aTableCell label"
            colspan="2"
          >
            {{ sumLabel(group) }}
          </td>
          <td class="aTableCell">
            <label>
              <input
                type="text"
                disabled
                :value="group.sum"
              >
            </label>
          </td>
          <td />
        </tr>
      </tfoot>
    </table>
  </section>
</template>

<script>
import {
  initializeLines,
  emptyCustomField,
  findNextIndex,
  summarize
} from '@/js/field-calculator'
import { filter, startsWith } from 'ramda'
import { request } from '@/js/request'

export default {
  props: {
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    initContents: {
      type: String,
      required: false,
      default: 'null'
    },
    initFieldGroups: {
      type: String,
      required: false,
      default: 'null'
    }
  },
  data: function () {
    return {
      deletePreselectionConfirmation: false,
      fieldGroups: [],
      fieldData: {},
      contents: '{}',
      preselectionName: '',
      preselections: [],
      chosenPreselection: null,
      chosenPreselectionName: '',
      changeable: false,
      initialized: false
    }
  },
  created: function () {
    const self = this

    return request('get', `${window.location.pathname}.json`, response => {
      initializeLines.call(self, response)

      request('get', self.preselectionPath(), response => {
        self.preselections = response.preselections
        self.changeable = response.changeable
        self.serializeContent()
      })
    })
  },
  methods: {
    summarize (field, group) {
      return summarize(this.fieldData, field, group)
    },
    sumLabel: function (group) {
      return 'Summe ' + group.label.split(' / ')[1]
    },
    serializeContent () {
      if (!this.initialized && this.initContents !== 'null') {
        this.fieldData = JSON.parse(this.initContents)
        this.fieldGroups = JSON.parse(this.initFieldGroups)
        this.initialized = true
      }

      // Remove entries with a label.
      this.contents = JSON.stringify(filter(obj => obj.label !== '', this.fieldData))
    },
    onSelectPreselection () {
      const choice = this.preselections
        .find(preselection => preselection.id === this.chosenPreselection)

      if (choice === undefined || choice === null) {
        return
      }
      this.preselectionName = choice.name
    },
    preselectionPath () {
      const topicKey = window.location.pathname.split('/').slice(-1)
      return `/api/v1/concept/preselection/${topicKey[0]}.json`
    },
    onSavePreselection () {
      const url = this.preselectionPath()
      const data = {
        name: this.preselectionName,
        contents: this.fieldData
      }
      const headers = {
        'Content-Type': 'application/json'
      }
      return request('post', url, () => {}, data, headers)
    },
    onApplyPreselection () {
      const choice = this.preselections
        .find(preselection => preselection.id === this.chosenPreselection)

      if (choice === undefined || choice === null) {
        return
      }

      this.fieldGroups.forEach(group => {
        group.sum = '0,00'

        for (const field in group.fields) {
          group.fields[field].product = '0,00'

          if (choice.contents instanceof Object &&
              field in choice.contents) {
            this.fieldData[field] = choice.contents[field]
            this.summarize(group.fields[field], group)
          }
        }
      })
      this.serializeContent()
    },
    showDeletePreselectionConfirmation () {
      const choice = this.preselections
        .find(preselection => preselection.id === this.chosenPreselection)

      if (choice === undefined || choice === null) {
        return
      }
      this.chosenPreselectionName = choice.name
      this.deletePreselectionConfirmation = true
    },
    onDeletePreselection () {
      const url = '/api/v1/concept/preselection/' + this.chosenPreselection + '.json'
      return request('delete', url, () => {
        this.chosenPreselection = null
        this.deletePreselectionConfirmation = false
      }, null, {
        'Content-Type': 'application/json'
      })
    },
    addLine (group, groupKey) {
      const nextIndex = Math.max(...this.fieldGroups.map(findNextIndex))
      const newFieldName = `custom_${nextIndex}`

      this.fieldData[newFieldName] = emptyCustomField(group)
      group.fields[newFieldName] = {
        product: '0,00'
      }
    },
    isCustomField (key) {
      return startsWith('custom_', key)
    }
  }
}
</script>
<style scoped>
#message-text {
  line-height: 24px;
}

.ui.message > .close {
  position: absolute;
  right: 0.5em;
  cursor: pointer;
}
</style>
